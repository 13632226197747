const getters = {
  sidebar: (state) => state.app.sidebar,
  border: (state) => state.app.border,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  roles: (state) => state.user.roles,
  appTitle: (state) => (title) => title ? title : state.settings.appTitle,
  accessKeyId: (state) => state.settings.accessKeyId,
  accessKeySecket: (state) => state.settings.accessKeySecket,
  isEntityRecognition: (state) => state.settings.isEntityRecognition,
  isReady: (state) => state.permission.isReady,
  showLogo: (state) => state.settings.sidebarLogo,
  logo: (state) => (state.user.logo ? state.user.logo : require('@/assets/images/logo/logo.png')),
  // systematicName: state => state.user.serverName : '',
  permission: (state) => state.user.permission,
  corporations: (state) => state.user.corporations,
};

export default getters;
