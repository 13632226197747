<template>
  <!-- isWhiteList ?  'has-padding' : ''-->
  <section :class="{ 'app-main': true, 'has-padding': true, 'hide-padding': $store.state.app.border.hide ? true : false }">
    <el-row class="breadcrumb">
      <Breadcrumb />
    </el-row>
    <router-view :key="key" />
  </section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';

export default {
  name: 'AppMain',
  components: {
    Breadcrumb,
  },
  data() {
    return {
      whiteList: ['main', 'welcome', 'enterprise', 'center', 'device', 'site', 'user', 'handle', 'dashboard', 'version', 'addSite', 'pandas', 'historicalInfomation', 'mission', 'recorders', 'schedule', 'tagZoom', 'message', 'projectgroup', 'menu', 'admin-role-group'],
      isWhiteList: false,
    };
  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  created() {
    //解决刷新时 元素的padding会无法生效问题
    this.handleRouter(this.key);
  },
  methods: {
    /**
     * @description: 监听是否为可渲染padding页面
     * @param {*} route
     * @return {*}
     */
    handleRouter(route) {
      this.isWhiteList = this.whiteList.findIndex((whiteItem) => route.includes(whiteItem)) >= 0 ? true : false;
    },
  },
  watch: {
    key(nR, oR) {
      this.handleRouter(nR);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-main {
  /*50 = navbar  */
  width: 100%;
  min-height: calc(100vh - 50px);
  height: calc(100vh - 50px);
  box-sizing: border-box;
  position: relative;
  background: var(--main-layout-container-background);

  &.hide-padding {
    width: 100% !important;
    margin-left: 0 !important;
    height: 100% !important;
  }

  /* overflow: hidden; */
  .breadcrumb {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--main-layout-container-breadcrumb-background);
    border: 1px solid var(--main-border-color);
  }

  &.has-padding {
    padding: 10px;
  }
}

.fixed-header + .app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
