import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL'
const server = base_url.server

function getPublicKey() {
  return request({
    url: `${server}/auth/authService/getPubKeys`,
    method: 'get'
  })
}

function getSecretKey(secretKey) {
  return request({
    url: `${server}/auth/authService/getSecretKey?secretKey=${secretKey}`,
    method: 'get'
  })
}

function getRoleManager(pageIndex = 1, pageSize = 12) {
  return request({
    url: `${server}/auth/roleManager/queryRoleInfoList?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    method: 'get'
  })
}

function getMenuManager(pageIndex, pageSize) {
  return request({
    url: `${server}/auth/menuManager/queryMenuInfoList`,
    method: 'post',
    data: { pageIndex, pageSize }
  })
}

function getRoleInfoDetail(roleId) {
  return request({
    url: `${server}/auth/roleManager/getRoleInfoDetail?roleId=${roleId}`,
    method: 'get'
  })
}

function getUserCorporationList(uId) {
  return request({
    url: `${server}/auth/userManager/selectCorporationList?userId=${uId}`,
    method: 'post'
  })
}
function getProjectsByCpIds(data) {
  return request({
    url: `${server}/auth/userManager/getProjectsByCpIds`,
    method: 'post',
    data
  })
}

function getSiteListBypId(data) {
  return request({
    url: `${server}/auth/userManager/getStationsByPids`,
    method: 'post',
    data
  })
}

function updateMenuSort(data) {
  return request({
    url: `${server}/auth/menuManager/updateMenuSort`,
    method: 'post',
    data
  })
}

function addMenu(data) {
  return request({
    url: `${server}/auth/menuManager/addMenu`,
    method: 'post',
    data
  })
}

function insertMenuInfo(data){
  return request({
    url:`${server}/auth/menuManager/addMenu`,
    method: 'post',
    data
  })
}



export {
  getPublicKey,
  getSecretKey,
  getRoleManager,
  getMenuManager,
  getRoleInfoDetail,
  getUserCorporationList,
  getProjectsByCpIds,
  getSiteListBypId,
  updateMenuSort,
  addMenu,
  insertMenuInfo
}