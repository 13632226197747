import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';

const server = base_url.server;

/* 登入 */
function login(params) {
  const { name: userName, password } = params;
  const rule = {
    account: /^[0-9a-zA-Z_]{3,15}$/,
    mobile: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
  };
  let url = '';
  if (rule.mobile.test(userName)) {
    url = `${server}/auth/login/mobilePw?mobile=${userName}&password=${password}&loginMode=web`;
  } else if (rule.account.test(userName)) {
    url = `${server}/auth/login?username=${userName}&password=${password}&loginMode=web`;
  }
  return request({ url, method: 'POST' });
}

//手机验证码登录
function loginMobileCode(data) {
  const { name, password } = data;
  return request({
    url: `${server}/auth/login/mobileSms?mobile=${name}&smsCode=${password}&loginMode=web`,
    method: 'post',
  });
}

//获取手机验证码
function getCode(phoneNum, templateType) {
  return request({
    url: `${server}/auth/authService/sendsms?phoneNum=${phoneNum}&templateType=${templateType}`,
    method: 'get',
  });
}

//获取用户权限
function getUserRole() {
  return request({
    url: `${server}/auth/common/checkToken`,
    method: 'get',
    // headers: {
    //   Authorization,
    //   refreshToken
    // }
  });
}

/* 获取用户列表 */
function getUserList(pageIndex, pageSize, userType = '', userName = '') {
  return request({
    url: `${server}/auth/userManager/queryUserInfoList?pageIndex=${pageIndex}&pageSize=${pageSize}&userType=${userType}&userName=${userName}`,
    method: 'get',
  });
}

//新增用户信息
function userRegistered(data) {
  return request({
    url: `${server}/auth/authService/register`,
    method: 'post',
    data,
  });
}

function editUserInfomation(data) {
  return request({
    url: `${server}/auth/userManager/updateUserBaseInfo`,
    method: 'post',
    data,
  });
}

function deleteUserInfomation(uId) {
  return request({
    url: `${server}/auth/userManager/deleteUserByUserId?userId=${uId}`,
    method: 'delete',
  });
}

function getMenuInforList({ pageIndex, pageSize, menuName }) {
  return request({
    url: `${server}/auth/menuManager/queryMenuInfoList?pageIndex=${pageIndex}&pageSize=${pageSize}&menuName=${menuName}`,
    method: 'post',
  });
}

function setupAssignRoles(data) {
  return request({
    url: `${server}/auth/userManager/assignRolesToUser`,
    method: 'post',
    data,
  });
}

function getRoleGroup(uId) {
  return request({
    url: `${server}/auth/userManager/selectRoleList?userId=${uId}`,
    method: 'post',
  });
}

function getRoleDetailsByRoleId(roleId) {
  return request({
    url: `${server}/auth/roleManager/getRoleInfoDetail?roleId=${roleId}`,
    method: 'get',
  });
}

function setNewPassword({ userMobile, msgCode, newPassword }) {
  return request({
    url: `${server}/auth/userManager/forgotPassword?userMobile=${userMobile}&msgCode=${msgCode}&newPassword=${newPassword}`,
    method: 'post',
  });
}

function getProjectListByUID(uId) {
  return request({
    url: `${server}/auth/userManager/selectProjectList?userId=${uId}`,
    method: 'post',
  });
}

//设置用户权限 & 项目-站点数据
function setupUserRole(data) {
  return request({
    url: `${server}/auth/userManager/assignRolesToUsers`,
    method: 'post',
    data,
  });
}

//获取角色菜单权限
function getRoleByRoleId(roleId) {
  return request({
    url: `${server}/auth/roleManager/selectMenuList?roleId=${roleId}`,
    method: 'post',
  });
}

//设置角色权限
function setRoleByRoleId(data) {
  return request({
    url: `${server}/auth/roleManager/assignMenusToRole`,
    method: 'post',
    data,
  });
}

/**
 * @description: 修改用户基本信息
 * @param {*}
 * @return {*}
 */
function usermod() {
  return request({
    url: `${server}/auth/userManager/updateUserBaseInfo`,
    method: 'post',
  });
}
/**
 * @description: 获取用户企业组织列表
 * @param {*} uId 用户编号
 * @return {*}
 */

function getCorporationList(uId) {
  return request({
    url: `${server}/auth/userManager/selectCorporationList?userId=${uId}`,
    method: 'post',
  });
}

/**
 * @description: 修改菜单信息
 * @param {*} data
 * @return {*}
 */
function updateMenuInfo(data) {
  return request({
    url: `${server}/auth/menuManager/updateMenuInfo`,
    method: 'post',
    data,
  });
}

//删除菜单信息
function deleteMenuInfo(id) {
  return request({
    url: `${server}/auth/menuManager/deleteMenuInfo?menuId=${id}`,
    method: 'get',
  });
}

function logout() {
  return request({
    url: `${server}/auth/login/userLogout`,
    method: 'get',
  });
}

//注销
function loggerOff() {
  return request({
    url: `${server}/auth/authService/userLoggedOff`,
    method: 'get',
  });
}

function changePassword(oldPass, newPass) {
  return request({
    url: `${server}/auth//userManager/updateUserPassword?oldPassword=${oldPass}&newPassword=${newPass}`,
    method: 'post',
  });
}

//修改用户头像

function updateUserProfilePhoto(formData) {
  return request({
    url: `${server}/auth/userManager/updateUserProfilePhoto`,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      transformRequest: [(data) => data], //此处是我进行设置的转换数据类型
    },
  });
}

//定制化需求

function customizeSystemInfo() {
  return request({
    url: `${server}/auth/authService/customizeSystemInfo`,
  });
}

export {
  login,
  loginMobileCode,
  getCode,
  getUserRole,
  logout,
  loggerOff,
  getUserList,
  userRegistered,
  editUserInfomation,
  deleteUserInfomation,
  getMenuInforList,
  getRoleGroup,
  getRoleDetailsByRoleId,
  setNewPassword,
  getProjectListByUID,
  setupUserRole,
  getRoleByRoleId,
  setRoleByRoleId,
  usermod,
  getCorporationList,
  updateMenuInfo,
  deleteMenuInfo,
  changePassword,
  setupAssignRoles,
  updateUserProfilePhoto,
};
