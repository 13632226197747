<script>
import router from '@/router';

export default {
  name: 'ForgotPassword',
  functional: true,
  props: {
    path: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
  render(h, context) {
    const { path, text, icon } = context.props;
    const vnodes = [];
    vnodes.push(
      icon ? (
        <span on-click={() => router.push(path)}>
          {text} <i class={icon}></i>{' '}
        </span>
      ) : (
        <span on-click={() => router.push(path)}>{text} </span>
      ),
    );
    return vnodes;
  },
};
</script>
