import NodeRSA from 'node-rsa'
import { getPublicKey, getSecretKey } from '@/api/role'
import { setAppPublicKey, getAppPublicKey, getAESKey } from '@/utils/auth'

const state = {
  publicKey: getAppPublicKey() || '',
  privateKeyStr: '',
  AESKey: getAESKey() || ''
};

const mutations = {
  CREATE_PUBLICKEY(state, e) {
    const key = new NodeRSA({ b: 512 })
    key.setOptions({ encryptionScheme: 'pkcs1' })
    const mk = key.exportKey('public-der').toString('base64')
    setAppPublicKey(mk)
    state.publicKey = mk
    const privateKeyStr = key.exportKey('pkcs8-private-der').toString('base64')
    const key2 = new NodeRSA({ b: 512 });
    key2.setOptions({ encryptionScheme: 'pkcs1' })
    key2.importKey(`-----BEGIN PUBLIC KEY-----\n${e}-----END PUBLIC KEY-----`, 'public')
    state.privateKeyStr = encodeURIComponent(key2.encrypt(privateKeyStr, 'base64'));
  },

};

const actions = {
  getPublicKey({ commit }) {
    return new Promise((resolve, reject) => {
      getPublicKey()
        .then(response => {
          const { reason, data } = response
          if (reason !== '请求成功') {
            return reject(reason)
          }
          commit('CREATE_PUBLICKEY', data)
          resolve(response)
        })
        .catch(error => {
          reject(new Error(error))
        })
    })

  },
  getSecretKey({ commit }, secretKey) {
    return new Promise((resolve, reject) => {
      getSecretKey(secretKey)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  decryptionAes({ commit }, EncryptionAESKey) {
    const key = new NodeRSA();
    key.setOptions({ encryptionScheme: 'pkcs1' })
    key.importKey(`-----BEGIN PUBLIC KEY-----\n${this.state.jsencrypt.publicKey}-----END PUBLIC KEY-----`, 'public')
    return key.decryptPublic(EncryptionAESKey, 'utf8')
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
