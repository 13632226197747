import io from 'socket.io-client';
import { getUserInformation } from "@/utils/auth.js"
import router from '../router'


class Socket {
  constructor() {
    this.socket = null;
  }
  initSocket() {
    /* 检测原型是否存在socket实例 */
    if (!this.socket) {
      const { socketInformaton: { IPAddress, accessKeyId, accessKeySecret } } = getUserInformation()
      const socketAddress = `${IPAddress}/user`
      const query = {
        accessKeyId: accessKeyId || '',
        accessKeySecret: accessKeySecret || '',
      }
      this.socket = io(socketAddress, { query });
      /* 建立连接中 */
      this.socket.on('connecting', () => {
        console.warn('connecting')
      })
      /* 监听建立连接 */
      this.socket.on('connect', () => {
        console.warn('connect')
      })
      /* 连接断开 */
      this.socket.on('disconnect', (e) => {
        console.error(e)
        console.error('disconnect')
      })

      /* 连接断开 */
      this.socket.on('connection notice', data => {
        console.log(data)
      })
      /* 检测账号被挤掉 */
      this.socket.on('user login', data => {
        /* 清除socket实例 */
        this.clearSocket()
        MessageBox.confirm('您的账号已在其他设备登录', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          router.push('/login')
        }).catch(() => {
          router.push('/login')
        })
      })
    }
    return this.socket;
  }
  clearSocket() {
    this.socket && this.socket.disconnect();
    this.socket = null;
  }
}

export default new Socket()
