<template>
  <div :class="{ 'has-logo': showLogo, hide: hideBorder }">
    <logo :showLogo="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu :default-active="activeMenu" :collapse="isCollapse" :background-color="variables.layoutSidleBackground" :text-color="variables.layoutSidleTextColor" :unique-opened="false" :active-text-color="variables.layoutSidleActiveTextColor" :collapse-transition="false" mode="vertical">
        <sidebar-item v-for="(route, index) in routes" :key="route.path + index" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from './Logo';
import SidebarItem from './SidebarItem';
import variables from '@/styles/theme.scss';

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters(['sidebar']),
    routes() {
      return this.$router.options.routes;
    },
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },

    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    hideBorder() {
      return this.$store.state.app.border.hide;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
};
</script>
