import defaultSettings from '@/settings';

const { showSettings, fixedHeader, sidebarLogo, offset, appTitle, isEntityRecognition } = defaultSettings;

const state = {
  showSettings: showSettings,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  offset: offset,
  appTitle: appTitle,
  isEntityRecognition: isEntityRecognition
};

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  },
};

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
