<template>
  <div class="gloab-search-bar-wrap">
    <el-cascader v-model="cascaderValue" filterable clearable :size="size" :props="{ lazy: true, lazyLoad: lazyLoad }" :style="{ width: width }" @change="handleCascaderValueChange" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCorporationBySelf } from '@/api/accraditation.js';
import { queryProjectListByCpID } from '@/api/project.js';
import statusInclude from '@/utils/statusCode';

export default {
  name: 'GlobalSearchBar',
  props: {
    hasSite: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '300px',
    },
    size: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      PID: null,
      cpId: null,
      cascaderValue: '',
      inputName: '',
      userId: '',
      onlyOneCompany: true,
    };
  },
  computed: {
    ...mapGetters(['corporations']),
  },
  methods: {
    handleRefresh() {
      this.cascaderValue = '';
    },
    async lazyLoad(node, resolve) {
      const { level, root } = node;
      let nodes = [];
      const promise = Promise.resolve();
      promise
        .then(
          () =>
            new Promise((resolve, reject) => {
              if (!this.corporations.length) return resolve([]); // 未绑定企业信息用户直接结束
              try {
                if (root) {
                  getCorporationBySelf()
                    .then((response) => {
                      return new Promise((resolve, reject) => {
                        if (!statusInclude(response.code)) {
                          reject(new Error({ code: 301, message: '请求企业信息失败' }));
                          return;
                        }
                        if (response.data.length === 1) {
                          resolve(response);
                        } else {
                          reject(response);
                        }
                      });
                    })
                    .then((response) => {
                      this.$emit('CPID:getCPID', { CPID: response.data[0].cpId });
                      queryProjectListByCpID(response.data[0].cpId).then((res) => {
                        const kyes = { value: 'pId', label: 'pName' };
                        nodes = this.getKeyValue({ source: res.data, ...kyes, leaf: !this.hasSite });
                        resolve(nodes);
                      });
                    })
                    .catch((result) => {
                      // 多企业信息 or 错误信息
                      if (statusInclude(result.code)) {
                        this.onlyOneCompany = false;
                        const kyes = { value: 'cpId', label: 'cpName' };
                        nodes = this.getKeyValue({ source: result.data, ...kyes, leaf: false });
                        resolve(nodes);
                      }
                    });
                } else if (level === 1) {
                  if (!this.onlyOneCompany) {
                    queryProjectListByCpID(node.data.value).then(({ code, data }) => {
                      if (statusInclude(code)) {
                        const kyes = { value: 'pId', label: 'pName' };
                        nodes = this.getKeyValue({ source: data, ...kyes, leaf: !this.hasSite });
                        resolve(nodes);
                      }
                    });
                  } else {
                    this.$store.dispatch('user/getSiteGroup', { page: 1, size: 1000, PID: [node.data.value] }).then((siteData) => {
                      const { code, data } = siteData;
                      if (statusInclude(code)) {
                        const kyes = { value: 'pId', label: 'pName' };
                        nodes = this.getKeyValue({ source: data, ...kyes, leaf: false });
                        resolve(nodes);
                      }
                    });
                  }
                }
              } catch (error) {
                reject(reject);
              }
            }),
        )
        .then(resolve);
    },
    // 获取key value
    getKeyValue({ source = [], value, label, leaf = false }) {
      return source.map((item) => ({ value: item[value], label: item[label], leaf }));
    },

    async handleCascaderValueChange(value) {
      const params = await new Promise((resolve) => {
        const len = value.length;
        const [CPID = '', PID = '', STID = ''] = value;
        switch (len) {
          case 1:
            resolve({ PID: CPID });
            break;
          case 2:
            resolve({ CPID, PID });
            break;
          case 3:
            resolve({ CPID, PID, STID });
            break;
          default:
            resolve({ CPID, PID, STID });
            break;
        }
      });
      this.$emit('get:checkedOption', { ...params });
    },
  },
};
</script>

<style></style>
