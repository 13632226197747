import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import settings from './modules/settings';
import permission from './modules/permission'
import project from './modules/project'
import user from './modules/user';
import mission from './modules/mission';
import site from './modules/site';
import content from './modules/content'
import jsencrypt from './modules/jsencrypt'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    settings,
    project,
    permission,
    user,
    mission,
    site,
    content,
    jsencrypt
  },
  getters,
});

export default store;
