/**
 * @description: 状态码白名单
 * @param {*} code
 * @return {*}
 * @author: 篆愁君
 */
export default function statusInclude(code) {
  //   320780101  网关
  // 320770101  鉴权中心
  // 320760101  媒体服务
  // 320790101  数据中心
  // 320770115  短信模块
  const whiteList = [0, 200, 320780101, 320770101, 320770115, 320790101, 320760101];
  return whiteList.includes(code);
}
