import Cookies from 'js-cookie';
const TokenKey = 'Admin-Token';

function setToken(token) {
  return Cookies.set(TokenKey, token);
}

function getToken() {
  return Cookies.get(TokenKey);
}

function removeToken() {
  return Cookies.remove(TokenKey);
}

function setAuthorization(Authorization) {
  return Cookies.set('Authorization', Authorization);
}

function getAuthorization() {
  return Cookies.get('Authorization');
}

function removeAuthorization() {
  return Cookies.remove('Authorization');
}

function setUserInformation(userInfo) {
  return Cookies.set('u', userInfo);
}

function getUserInformation() {
  return Cookies.get('u') && JSON.parse(Cookies.get('u'));
}

//企业信息过大时Cookies 不能写入  应该是超过 cookie的最大储存了
function setUserCorporationsInformation(corporation) {
  return sessionStorage.setItem('corporation', JSON.stringify(corporation));
}
function getUserCorporationsInformation() {
  return sessionStorage.getItem('corporation') ? JSON.parse(sessionStorage.getItem('corporation')) : [];
}

function removeUserCorporationsInformation() {
  return Cookies.remove('corporation');
}
function removeUserInformation() {
  return Cookies.remove('u');
}

function setSocketAccount(socketAccount) {
  return Cookies.set('socketAccount', socketAccount);
}

function getSocketAccount() {
  return Cookies.get('socketAccount') && JSON.parse(Cookies.get('socketAccount'));
}

function removeSocketAccount() {
  return Cookies.remove('socketAccount');
}

function setAppPublicKey(AppPublicKey) {
  return Cookies.set('AppPublicKey', AppPublicKey);
}

function getAppPublicKey() {
  return Cookies.get('AppPublicKey');
}

function removeAppPublicKey() {
  return Cookies.remove('AppPublicKey');
}

function setAESKey(AESKey) {
  return Cookies.set('AESKey', AESKey);
}

function getAESKey() {
  return Cookies.get('AESKey');
}

function removeAESKey() {
  return Cookies.remove('AESKey');
}

function setRoles(roles) {
  return Cookies.set('r', roles);
}

function getRoles() {
  return Cookies.get('r');
}

function removeRoles() {
  return Cookies.remove('r');
}

//开辟缓存存储用户信息
function developuCache(userInformation) {
  return Cookies.set('ui', userInformation);
}

//
function getuCache() {
  return Cookies.get('ui');
}

//回收缓存的用户信息
function recycleuCache() {
  return Cookies.remove('ui');
}

export {
  setToken,
  getToken,
  removeToken,
  setAuthorization,
  getAuthorization,
  removeAuthorization,
  setUserInformation,
  getUserInformation,
  removeUserInformation,
  setUserCorporationsInformation,
  getUserCorporationsInformation,
  removeUserCorporationsInformation,
  setSocketAccount,
  getSocketAccount,
  removeSocketAccount,
  setAppPublicKey,
  getAppPublicKey,
  removeAppPublicKey,
  setAESKey,
  getAESKey,
  removeAESKey,
  setRoles,
  getRoles,
  removeRoles,
  developuCache,
  getuCache,
  recycleuCache,
};
