<template>
  <button :class="['get-verification-code', time ? 'get-verification-code--disabled' : '']" :disabled="time ? true : false" @click="getVerificationCode">
    {{ time ? `请${time}后重试` : '获取验证码' }}
  </button>
</template>

<script>
import { validateuPhone } from '@/utils/validate.js';
import { getCode } from '@/api/user.js';
import statusInclude from '@/utils/statusCode';

export default {
  props: {
    phone: {
      type: String,
      default: '',
    },
    templateType: {
      type: String,
      default: 'register',
    },
  },
  data() {
    return {
      time: 0,
    };
  },
  methods: {
    // 请求验证码
    async getVerificationCode() {
      if (!this.phone) {
        return this.$message.warning('请填写手机号');
      }
      if (!validateuPhone(this.phone)) {
        return this.$message.warning('手机号格式不正确');
      }
      this.time = 60;
      const setTimeToInterval = (delay = 1000) => {
        if (!this.time) return;
        this.time -= 1;
        setTimeout(() => {
          setTimeToInterval(delay);
        }, delay);
      };
      setTimeToInterval(1000);
      const { code, reason } = await getCode(this.phone, this.templateType);
      if (!statusInclude(code)) return this.$message.error(reason);
      this.$message.success(reason);
    },
  },
};
</script>

<style lang="scss" scoped>
.get-verification-code {
  display: inline-block;
  width: 90px;
  padding: 9px;
  background-color: transparent;
  border: none;
  &:hover {
    color: #409eff;
    cursor: pointer;
  }
  &--disabled {
    color: #616161;
    &:hover {
      color: #616161;
      cursor: not-allowed;
    }
  }
}
</style>
