import { login, loginMobileCode, getUserRole, logout } from '@/api/user';
import { getToken, removeToken, setUserInformation, getRoles, setRoles, removeAuthorization, removeRoles, removeUserInformation, setUserCorporationsInformation, removeUserCorporationsInformation } from '@/utils/auth';
import statusInclude from '@/utils/statusCode';
import CombinationPermissions from '@/utils/combination-permissions.js';
// import { decrypt, encrypt } from '@/utils/darkBox'
import SetupCrypt from '@/utils/darkBox.js';
import { ROLE } from '../../enum/ROLE';
const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    roles: getRoles(),
    logo: '',
    permission: [],
    corporations: [],
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_USERROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_LOGO(state, logo) {
    state.logo = logo;
  },
  SET_SERVERNAME(state, name) {
    console.log(name);
    state.serverName = name;
  },
  SET_PERMISSION(state, permission) {
    state.permission = permission;
  },
  SET_CORPORATIONS(state, corporations) {
    state.corporations = corporations;
  },
};

const actions = {
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      const { loginMode } = userInfo;
      switch (loginMode) {
        case 1:
          login(userInfo)
            .then((response) => {
              const { code, reason, Authorization, refreshToken } = response;
              if (statusInclude(code)) {
                commit('SET_TOKEN', refreshToken);
                resolve(response);
              } else {
                reject(response);
              }
            })
            .catch((error) => {
              reject(error);
            });
          break;
        case 2:
          // smsCode
          loginMobileCode(userInfo)
            .then((response) => {
              const { code, reason, Authorization, refreshToken } = response;
              if (statusInclude(code)) {
                commit('SET_TOKEN', refreshToken);
                resolve(response);
              } else {
                reject(response);
              }
            })
            .catch((error) => {
              reject(error);
            });
          break;
      }
    });
  },

  /**
   * @description: 获取用户权限信息
   * @param {*} commit
   * @param {*} Authorization
   * @param {*} refreshToken
   * @return {Promise}
   */
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUserRole()
        .then((response) => {
          const { code, reason, data } = response;
          if (!statusInclude(code)) return reject(response);
          try {
            const { menuInfos, trialInfo, userAvater, userEmail, userId, userMobile, userName, socketInfos, userRoles, idcardName, corporations, nickName } = data;
            const [{ ipaddress: IPAddress, accesskeyid: accessKeyId, accesskeysecret: accessKeySecret }] = socketInfos;
            const [{ validLevel, roleId, roleName }] = userRoles;
            const userInformation = { roleId, roleName, userAvater, userEmail, userId, userMobile, userName, idcardName, trialInfo, nickName };
            const socketInformaton = { IPAddress, accessKeyId, accessKeySecret };
            const permission = CombinationPermissions(userRoles);

            setUserInformation({ socketInformaton, userInformation });
            setUserCorporationsInformation(corporations);
            setRoles({ validLevel, roleName });

            commit('SET_USERROLES', [{ validLevel, roleName }]);
            commit('SET_PERMISSION', permission);
            commit('SET_AVATAR', userAvater);
            commit('SET_CORPORATIONS', corporations);

            // 获取系统设置
            if (corporations.length) {
              const [{ systemIcon, systemName }] = corporations;
              console.log(systemIcon, systemName);
              commit('SET_SERVERNAME', systemName);
              commit('SET_LOGO', systemIcon);
            }

            resolve(menuInfos);
          } catch (error) {
            reject(error);
          }
        })
        .catch((error) => {
          removeToken();
          removeAuthorization();
          removeRoles();
          removeUserInformation();
          removeUserCorporationsInformation();
          commit('RESET_STATE');
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      logout()
        .then((response) => {
          console.log(response);
          removeToken();
          removeAuthorization();
          removeRoles();
          removeUserInformation();
          removeUserCorporationsInformation();
          commit('RESET_STATE');
          resolve(true);
        })
        .catch((error) => {
          removeToken();
          removeAuthorization();
          removeRoles();
          removeUserInformation();
          removeUserCorporationsInformation();
          commit('RESET_STATE');
          resolve(true);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      removeToken();
      removeAuthorization();
      removeRoles();
      removeUserInformation();
      removeUserCorporationsInformation();
      commit('RESET_STATE');
      resolve(true);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
