import axios from 'axios';
import { getToken, getAuthorization } from '@/utils/auth';
import store from '../store';
import router from '@/router'

const service = axios.create({
  timeout: 60000,
  'X-Requested-With': false,
  withCredentials: false,
});

service.interceptors.request.use(
  config => {
    // config.headers.post['Content-Type'] = "Content-Type', 'application/json; charset=utf-8";
    const Authorization = getAuthorization() || '';
    const refreshToken = getToken() || '';
    if (Authorization && refreshToken) {
      config.headers.Authorization = Authorization
      config.headers.refreshToken = refreshToken
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    const { data } = response;
    if (data.code === 320780205 || data.code === 320780103) {
      store.dispatch('user/resetToken').then(() => {
        router.push('/login')
        store.commit('permission/CLEARROUTES', false);
        location.reload()
      })
    }
    return data;
  },
  error => {
    return Promise.reject(error);
  }
);

export default service;
