/*
 * @Author: 篆愁君
 * @Date: 2021-06-03 16:59:23
 * @LastEditors: leibin leibin@skysys.cn
 * @LastEditTime: 2022-09-22 15:29:10
 * @Description: file content
 */
/**
 * Created by PanJiaChen on 16/11/18.
 */

export function validateuPhone(value) {
  const reg = /^(?:(?:\+|00)86)?1\d{10}$/;
  if (reg.test(value)) return true;
  return false;
}

export function validateuAccount(value) {
  const reg = /^[a-zA-Z0-9_-]{3,16}$/;
  if (reg.test(value)) return true;
  return false;
}

// 校验手机号
export function validatePhone(rule, value, cb) {
  if (!value) return cb('请输入手机号');
  if (validateuPhone(value)) return cb();
  return cb('手机号格式不正确');
}

// 校验手机验证码
export function validateVerificationCode(rule, value, cb) {
  const reg = /^[0-9a-zA-Z]{6}$/;
  if (!value) return cb('请输入验证码');
  if (reg.test(value)) return cb();
  return cb('验证码格式不正确');
}

// 校验登录账号
export function validateAccount(rule, value, cb) {
  if (!value) return cb('请输入账号');
  if (validateuPhone(value) || validateuAccount(value)) return cb();
  return cb('账号手机号格式不正确');
}

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

// 注册校验规则
export function validateRegisterAccount(rule, value, cb) {
  if (!value) return cb('请输入用户名');
  const reg = /^[a-zA-Z0-9_-]{3,9}$/;
  if (reg.test(value)) {
    return cb();
  }
  return cb('3-9位，字母、数字、下划线、减号');
}

// 密码校验规则
export function validatePassword(rule, value, cb) {
  const reg = /^[\w]{6,16}$/;
  if (!value) return cb('请输入密码');
  if (reg.test(value) && value.indexOf('@') === -1) return cb();
  return cb('6~16位，包含大小写字母和数字的组合');
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}
