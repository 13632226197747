import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import Moment from 'moment';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store';
import VueClipBoard from 'vue-clipboard2'
import plugin from '@/utils/plugin';
import { message } from '@/utils/message.js';
import * as filters from "@/utils/filters.js";
import components from '@/components/index.js';
import Directive from '@/utils/directive.js';
import * as echarts from 'echarts';
import '@/permission';
import { ROLE } from './enum/ROLE';
import * as Cesium from 'cesium/Cesium';
// https://skyscout.skysys.cn 站点限制
Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzOTlkMWI0YS1kNGI0LTQ5NzctOTYzNS01YzE3NWFlYjVjNDciLCJpZCI6MTM0ODY3LCJpYXQiOjE2ODIwNjE4OTJ9.b0SjxCBGhlyGN7atLNt0R3jNxE-6pM_Yk-M3U0WAcCc';
const LANGUAGE = 'skysys_language';
const toENSite = () => {
  localStorage.setItem(LANGUAGE, 'en');
  window.location.href = window.location.origin + '/en-us/' + window.location.hash;// 跳转至英文
}

let lang = localStorage.getItem(LANGUAGE);
if (lang && lang == 'en') {
  window.location.href = window.location.origin + '/en-us/' + window.location.hash;// 跳转至英文
}

Vue.use(ElementUI);
Vue.use(VueClipBoard);
Vue.use(components);
Vue.use(plugin);
Vue.prototype.$toENSite = toENSite;
Vue.prototype.$echarts = echarts;
Vue.prototype.$message = message;
Vue.config.productionTip = false;
Vue.prototype.ROLE = ROLE; // 全局角色清单
Vue.filter('converTime', (data, formatStr) => Moment(data).format(formatStr));
Moment.locale('zh-cn');
Vue.prototype.$moment = Moment;
Directive(Vue);

/**
 * 全局过滤规则
 */
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
