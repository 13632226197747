import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';

const server = base_url.server;

//审批列表
export function approvalCenterList(page, size, name, approvalStatus, status) {
  return request({
    url: `${server}/auth/dataAuthApproval/approvalCenterList?pageIndex=${page}&pageSize=${size}&taskName=${name}&approvalStatus=${approvalStatus}&status=${status}`,
    method: 'get',
  });
}

//获取我的工单
export function myApprovalTaskList(page, size, name = '', status = '') {
  return request({
    url: `${server}/auth/dataAuthApproval/MyApprovalTaskList?pageIndex=${page}&pageSize=${size}&taskName=${name}&status=${status}`,
    method: 'get',
  });
}

//新增工单信息
export function addApprovalTask(data) {
  return request({
    url: `${server}/auth/dataAuthApproval/newApprovalTask`,
    method: 'post',
    data,
  });
}

export function undoMyApprovalTask(taskId) {
  return request({
    url: `${server}/auth/dataAuthApproval/cancelApprovalTask?taskId=${taskId}`,
    method: 'get',
  });
}

//根据当前用户获取可选企业
export function getCorporationBySelf(mobile) {
  return request({
    url: `${server}/auth/dataAuthApproval/selectCorporationBySelf`,
    method: 'post',
    data: { approvalUserMobile: mobile },
  });
}

// 根据企业列表获取项目
export function getProjectBycpIds(cpIds) {
  return request({
    url: `${server}/auth/dataAuthApproval/selectProjectByCorporations`,
    method: 'post',
    data: { cpIds },
  });
}

export function getStationsByProjects(pIds) {
  return request({
    url: `${server}/auth/dataAuthApproval/selectStationsByProjects`,
    method: 'post',
    data: { pids: pIds },
  });
}

export function getApprovalTaskDetail(taskId) {
  return request({
    url: `${server}/auth/dataAuthApproval/approvalTaskDetail?taskId=${taskId}`,
    method: 'get',
  });
}

export function getUserRole() {
  return request({
    url: `${server}/auth/dataAuthApproval/getSelectRoleListByCurrentUser`,
    method: 'get',
  });
}

export function submitApproval(data) {
  return request({
    url: `${server}/auth/dataAuthApproval/submitApproval`,
    method: 'post',
    data,
  });
}

export function updateApprovalTask(data) {
  return request({
    url: `${server}/auth/dataAuthApproval/updateApprovalTask`,
    method: 'post',
    data,
  });
}
