

import createEnum from "./createEnum"

export const SITE_HIVE_TYPE = {
  SITEMODE: createEnum({ //1-固定机库+射频箱 2-射频箱 3-单兵中枢 4-移动机库 5-无人机遥控器 6-天枢
    hiveAndRadioFrequencyBox: [1, '机库+射频基站'],
    radioFrequencyBox: [2, '射频基站'], // 开机库
    grandHub: [3, '单兵中枢'],
    uavController: [4, '无人机遥控器'],
    onboard: [5, '机载计算机'],
  }, '未知'),

  HIVETYPE: createEnum({
    fixed: [1, '固定机库'],
    mobile: [2, '移动机库'],
  }, '未知'),

  HIVEMODEL: createEnum({
    UH_MK3: ['UH_MK3', 'SKYSYS UltraHive Mk3'], // 
    UH_MK4: ['UH_MK4', 'SKYSYS UltraHive MK4'], // 
    UH_MK4_Pro: ['UH_MK4_Pro', 'SKYSYS UltraHive MK4 Pro'],
    UH_MKX: ['UH_MKX', 'SKYSYS UltraHive MkX'],
    UH_MK5: ['UH_MK5', 'SKYSYS UltraHive MK5'],
    UH_MK5PLC: ['UH_MK5PLC', 'SKYSYS UltraHive MK5PLC'],
    MH_MK3: ['MH_MK3', 'SKYSYS MobileHive Mk3'], // 移动机库
    WK_YU_JK: ['WK_YU_JK', 'ZHONGYAN UltraHive WK_YU_JK'],
    UH_4PLC: ['UH_4PLC', 'SKYSYS MobileHive MK4PLC'],
    UH_MK6: ['UH_MK6', 'SKYSYS UltraHive MK6'],
  }, '未知'),
}





