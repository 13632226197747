import { routerMap as asyncRoutes } from '@/router/routerMap.config.js'
const state = {
  routes: []
}

function hasPermission(route) {
  const node = {}
  matchingRouter(route).then(response => {
    console.log(response)
  })

  console.log(route)
}

function matchingRouter(route) {
  return new Promise((resolve, reject) => {
    Object.keys(asyncRoutes).forEach(key => {
      if (route.menuUrl === key) {
        const tmp = asyncRoutes[key]
        console.log(tmp)
        const node = route
        resolve(node)
      }
    })
  })
}

export function filterAsyncRoutes(routes) {
  console.log(routes)
  const res = []
  routes.forEach(route => {
    console.log(route)
    const tmp = hasPermission(route)
    if (tmp.children) {
      tmp.children = filterAsyncRoutes(tmp.children)
    }
    res.push(tmp)
  })
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = routes//constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, asyncRoutes) {
    return new Promise(resolve => {
      console.log(asyncRoutes)
      const accessedRoutes = filterAsyncRoutes(asyncRoutes)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

