/*
 * @Author: 篆愁君
 * @Date: 2021-10-17 17:02:27
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-02-18 17:55:04
 * @Description: file content
 */
export default Vue => {
  /***
  *  防抖 单位时间只触发最后一次
  *  @param {?Number|300} time - 间隔时间
  *  @param {Function} fn - 执行事件
  *  @param {?String|"click"} event - 事件类型 例："click"
  *  @param {Array} binding.value - [fn,event,time]
  *  例：<el-button v-debounce="[reset,`click`,300]">刷新</el-button>
  *  也可简写成：<el-button v-debounce="[reset]">刷新</el-button>
  */
  Vue.directive('debounce', {
    inserted: function (el, binding) {
      let [fn, event = "click", param = {}, time = 300] = binding.value
      let timer
      el.addEventListener(event, () => {
        timer && clearTimeout(timer)
        timer = setTimeout(() => fn(param), time)
      })
    }
  })
}
