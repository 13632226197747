import axios from 'axios';
const instance = axios.create({
  timeout: 60000,
  withCredentials: false,
});

async function getUserNameAndPassword() {
  return instance.get(`https://center1.zhihuihedao.cn/center-api/auth-center-api/account/open/geths`)
    .then(({ data: res }) => res);
}

export {
  getUserNameAndPassword
}