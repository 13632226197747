const state = {
  init: false,
};

const actions = {
};

const mutations = {
  INIT_TIMEDTASK(state, e) {
    state.init = e;
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
