/**
 * add remove edit 权限
 * -E 设备  -S 站点 -P 项目
 */

export default function CombinationPermissions(roles) {
  let permission = []
  roles.forEach(({ roleId }) => {
    switch (roleId) {
      case 'R0001':
        break;
      case 'R0005':
      case 'R0006':
        // permission = ['add-P', 'edit-P', 'remove-P']
        permission = ['add', 'remove', 'edit', 'add-P', 'edit-P', 'remove-P', 'add-E', 'edit-E', 'remove-E', 'add-S', 'edit-S', 'remove-S'];
        break;
      case 'R0002':
        permission = ['edit-E', 'edit-S'];
        break;
      case 'R0003':
        permission = ['add', 'remove', 'remove-P', 'add-E', 'edit-E', 'remove-E', 'add-S', 'edit-S', 'remove-S'];
        break;
      case 'R0004':
        permission = ['add', 'remove', 'edit', 'add-P', 'edit-P', 'remove-P', 'add-E', 'edit-E', 'remove-E', 'add-S', 'edit-S', 'remove-S'];
        break;
    }
  })

  return permission
};