<template>
  <div :class="['s-alert', `s-alert-wrapper-${type}`]">
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'SAlert',
  props: {
    type: {
      type: String,
      default: 'success',
    },
  },
};
</script>

<style lang="scss" scoped>
.s-alert {
  padding: 8px 16px;
  color: #fff;
  border-radius: 4px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  > span {
    font-size: 14px;
  }
}
.s-alert-wrapper- {
  &default {
    background: #{$bDefault};
    span {
      color: #fff;
    }
  }
  &primary {
    background: #{$bPrimary};
    span {
      color: #5b9cd6;
    }
  }
  &success {
    background: #{$bSuccess};
    span {
      color: #36c549;
    }
  }
  &error {
    background: #{$bError};
    span {
      color: #f56c6c;
    }
  }
  &wraning {
    background: #{$bError};
    span {
      color: #f56c6c;
    }
  }
  &warning {
    background: #{$warning};
  }
}
</style>
