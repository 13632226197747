import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';

const server = base_url.server

export function getNotictMsgList(page, size, msgType, searchContent, searchType, status) {
  return request({
    url: `${server}/auth/msgNoticeManage/MyNoticeMsgList?pageIndex=${page}&pageSize=${size}&msgType=${msgType}&searchContent=${searchContent}&searchType=${searchType}&status=${status}`,
    method: 'get'
  })
}

//将消息标记已读
export function noticeMsgRead(data) {
  return request({
    url: `${server}/auth/msgNoticeManage/noticeMsgRead`,
    method: 'post',
    data
  })
}

export function noticeMsgDetail(msgId) {
  return request({
    url: `${server}/auth/msgNoticeManage/noticeMsgDetail?msgId=${msgId}`,
    method: 'get'
  })
}