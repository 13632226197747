import store from '@/store';

async function checkPermission(el, binding) {
  const { value } = binding
  const roles = await store.getters && store.getters.permission
  if (value && value instanceof Array) {
    if (value.length > 0) {
      const permissionRoles = value
      const hasPermission = roles.some(role => {
        return permissionRoles.includes(role)
      })
      if (!hasPermission) {
        const attrs = el.className.split(/\s+/)
        if (attrs.includes('is-disabled')) return
        el.className += ' is-disabled'
        el.setAttribute('disabled', true)
        // el.parentNode && el.parentNode.removeChild(el)
      }
    }
  } else {
    throw new Error('需要以数组的形式传角色')
  }
}

export default {
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  update(el, binding) {
    checkPermission(el, binding)
  }
}