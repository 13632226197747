import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';

Vue.use(Router);
export const constantRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index'),
  },

  {
    path: '/login:mode',
    name: 'Login',
    component: () => import('@/views/login/index'),
  },

  {
    path: '/wechat/login/wechat',
    name: 'Login',
    component: () => import('@/views/login/index'),
  },

  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/index'),
  },

  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/resetPassword/index'),
  },

  {
    path: '/handleCenter',
    name: 'HandleCenter',
    component: () => import('@/views/handleContent/index.vue'),
  },
  {
    path: '/webrtcView',
    name: 'webrtcView',
    component: () => import('@/views/webrtcView/index.vue'),
  },

  {
    path: '/timer',
    name: 'Timer',
    component: () => import('@/views/timer/index.vue'),
  },

  // {
  //   path: '/scan',
  //   component: Layout,
  //   hidden: true,
  //   redirect: '/scan',
  //   children: [
  //     {
  //       path: '/scan',
  //       name: 'Scan',
  //       component: () => import('@/views/mission/scan/index'),
  //       meta: { title: '二维扫图', icon: 'icon-shujuzhongxin' },
  //     },
  //   ],
  // },

  {
    path: '/historicalInformation',
    name: 'HistoricalInformation',
    component: () => import('@/views/flightpath/index.vue'),
  },

  {
    path: '/grid-result',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/grid-result',
        name: 'GridResult',
        meta: { title: '成果' },
        component: () => import('@/views/results/grid/index.vue'),
      }
    ]
  },

  // {
  //   path: '/',
  //   component: Layout,
  //   hidden:true,
  //   redirect: '/main',
  //   children: [
  //     {
  //       path: 'main',
  //       name: 'main',
  //       component: () => import('@/views/main/index'),
  //       meta: { title: '数据中心', icon: 'icon-shujuzhongxin' },
  //     }
  //   ],
  // },

  // {
  //   path: '/monitoring',
  //   component: Layout,
  //   hidden: false,
  //   redirect: '/monitoring',
  //   children: [
  //     {
  //       path: '/monitoring',
  //       name: 'Monitoring',
  //       component: () => import('@/views/monitoring/index'),
  //       meta: { title: '视频监控', icon: 'icon-shujuzhongxin' },
  //     },
  //   ],
  // },
  {
    path: '/',
    component: Layout,
    hidden: false,
    redirect: '/main',
    children: [
      {
        path: 'main',
        name: 'main',
        component: () => import('@/views/main/index'),
        meta: { title: '数据中心', icon: 'icon-shujuzhongxin' },
      },
    ],
  },

  {
    path: '/control',
    component: Layout,
    rediren: '/control/center',
    children: [
      {
        path: 'center',
        component: () => import('@/views/ControlCenter'),
        name: 'Center ',
        hidden: true,
        meta: { title: '飞行控制', icon: 'icon-menu_kongzhitai' },
      },
    ],
  },

  {
    path: '/version',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/version',
        name: 'Version',
        meta: { title: '版本信息' },
        component: () => import('@/views/version'),
      },
    ],
  },

  {
    path: '/personal',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/personal',
        name: 'Personal',
        meta: { title: '个人信息' },
        component: () => import('@/views/user/Personal'),
      },
    ],
  },
  {
    path: 'external-link',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'http://qiyun.skysys.cn/#/',
        meta: { title: 'External Link', icon: 'link' },
      },
    ],
  },
];

//获取原型对象上的push函数
const originalPush = Router.prototype.push;

//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const createRouter = () =>
  new Router({
    mode: 'hash',//'history',
    // mode: 'history',//'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;
