<template>
  <div class="navbar" :class="border.hide ? 'hide' : ''">
    <!-- 收缩侧边栏 -->
    <Hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
    <div class="right-menu">
      <ToggerTheme />
      <!-- <i class="message-alert">
        <el-popover placement="bottom" width="300" trigger="click">
          <el-tabs size="mini" v-model="messageType" @tab-click="handleClick">
            <el-tab-pane label="默认" name="0">
              <MessageList :messages="messages" @getNewMessageList="getNewMessageList(0)" key="base" />
            </el-tab-pane>
            <el-tab-pane label="权限审批" name="1">
              <MessageList :messages="messages" @getNewMessageList="getNewMessageList(1)" key="role" />
            </el-tab-pane>
          </el-tabs>
          <el-badge :is-dot="messageTotal > 0 ? true : false" class="item" slot="reference">
            <i class="el-icon-message-solid"></i>
          </el-badge>
        </el-popover>
      </i> -->
      <el-link :href="source" class="iconfont icon-bangzhuwendang1" title="参考手册" target="_blank"></el-link>
      <i class="iconfont icon-caozuorizhi" @click="$router.push({ name: 'Version' })" title="版本信息"></i>
      <i :class="[fullscreen ? 'icon-quanpingsuoxiao' : 'icon-quanpingxianshi', 'iconfont']" @click="screen" title="全屏"></i>
      <!-- <i class="el-icon-s-marketing" @click="$router.push('/main')" title="统计数据"></i> -->
      <el-dropdown class="select-language lang-wrap" trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
         中文<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu>
          <el-dropdown-item command="toEn">English</el-dropdown-item>
          <el-dropdown-item disabled>中文</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <img :src="`${avatar}?${Math.random()}`" class="user-avatar" />
          <i class="el-icon-caret-bottom" />
        </div>

        <el-dropdown-menu slot="dropdown">
          <router-link to="/personal">
            <el-dropdown-item>
              <i class="el-icon-user-solid"></i>
              个人信息
            </el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="loggerOff" v-if="false">
            <el-button type="text" icon="iconfont el-icon-coin" style="color: #f40">注销</el-button>
          </el-dropdown-item>
          <el-dropdown-item @click.native="logout">
            <el-button type="text" icon="iconfont icon-tuichu">退出</el-button>
            <!-- <span style="display:block;"></span> -->
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Hamburger from '@/components/Hamburger';
import Breadcrumb from '@/components/Breadcrumb';
import MessageList from '@/components/MessageList';
import ToggerTheme from '@/components/ToggerTheme';
import socket from '@/utils/socket.js';
import { loggerOff } from '@/api/user.js';
import { getNotictMsgList } from '@/api/message';
import statusInclude from '@/utils/statusCode';

export default {
  components: {
    Hamburger,
    Breadcrumb,
    MessageList,
    ToggerTheme,
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'appTitle', 'border']),
  },
  watch: {
    $route: {
      deep: true,
      handler(to, form) {
        this.routerMatch(to.path);
      },
    },
  },
  data() {
    return {
      fullscreen: false,
      socketInstance: null,
      messageType: '0',
      messageTotal: 0,
      source: 'http://manual.skysys.cn/#',
      messages: [],
    };
  },
  created() {
    this.routerMatch(this.$route.path);
    this.getNotictMsgList(0);
  },
  methods: {
    handleCommand(cmd){
      if(cmd=='toEn'){
        this.$toENSite();
      }

    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },

    //切换消息类型
    handleClick(tag) {
      const msgType = tag.$options.propsData.name;
      this.getNotictMsgList(Number(msgType));
    },
    getNewMessageList(msgType) {
      this.getNotictMsgList(msgType);
    },

    async loggerOff() {
      this.$confirm('将永久此账, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code, data, reason } = await loggerOff();
          if (!statusInclude(code)) return this.$message.error(`注销失败：${reason}`);
          this.$message.success('账号注销成功');
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },

    async logout() {
      /* 退出后断开socket连接 */
      socket.initSocket().disconnect();
      /* 清除socket实例 */
      socket.clearSocket();
      this.$store.dispatch('user/logout').then((res) => {
        this.$store.commit('permission/CLEARROUTES', false);
        window.location.href = window.location.origin || `${window.location.protocol}:${window.location.host}`;
      });
      //清理路由缓存
    },
    screen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    routerMatch(path) {
      this.source = 'http://manual.skysys.cn/#';
      const routerMeta = {
        '/main': '首页',
        '/control/center': '站点管理',
        '/enterprise/index': '概述',
        '/schedule/scheduleList': '计划任务管理',
        '/device/hiveList': '设备管理',
        '/device/uavList': '设备管理',
        '/device/batteryList': '设备管理',
        '/device/hub': '设备管理',
        '/device/rc': '设备管理',
        '/device/oc': '设备管理',
        '/recorders/recordersList': '数据统计',
      };
      this.source += routerMeta[path] ? routerMeta[path] : '概述';
    },
    async getNotictMsgList(msgType) {
      const { code, data, reason } = await getNotictMsgList(1, 500, msgType, '', '', 0);
      if (!statusInclude(code)) return this.$message.error(`请求失败${reason}`);
      this.messages = data.records;
      this.messageTotal = data.total;
    },
  },
};
</script>

<style lang="scss" scoped>
.lang-wrap{
  color: white;
  margin-top: 0px;
  margin-right: 5px;
}
.select-language{
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbar {
  height: 50px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: var(--main-layout-navbar-background);
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  &.hide {
    display: none;
  }

  h1.nav-bar-title {
    font-size: 18px;
    padding: 0;
    margin: 0;
    color: #{$blue};
    font-family: 'Alibaba-PuHuiTi-Medium';
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .message-alert {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      .el-badge {
        display: flex;
        flex-direction: row;
        align-items: center;

        .el-badge__content {
          font-size: 10px;

          &.is-fixed {
            top: 12px !important;
          }
        }
      }

      .message-list-wrap {
        width: 280px;
        padding: 10px;
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 999;
        background: #fff;
      }
    }

    .el-link,
    > i {
      font-style: normal;
      line-height: 50px;
      margin-right: 20px;
      color: var(--color-primary);
      cursor: pointer;
    }

    i:nth-child(1) {
      font-size: 18px;
    }

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .avatar-wrapper {
        position: relative;
        box-sizing: border-box;

        .user-avatar {
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 4px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
