const state = {
  distribute: {
    mileage: 0,
    timeoflight: 0
  }
};

const mutations = {
  SET_MISSION_DISTANCE(state, value) {
    state.distribute.mileage = value
  },
  SET_MiSSION_TIME(state, value) {
    state.distribute.timeoflight = value
  }
};

const actions = {

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
