import permission from '@/utils/permission';
export default vue => {
  vue.directive('drag', {
    inserted: function (el, binding, vnode) {
      let modalBox = el;
      let watchWidth = modalBox.offsetParent.offsetWidth;
      let watchHeight = modalBox.offsetParent.offsetHeight;
      modalBox.onmousedown = e1 => {
        let arr = document.querySelector('.video-drag-top');
        if (arr) {
          arr.className = arr.className.replace('video-drag-top', '')
        }
        el.className = el.className + ' video-drag-top'
        modalBox.style.cursor = 'pointer';
        let width = e1.clientX - modalBox.offsetLeft;
        let height = e1.clientY - modalBox.offsetTop;

        let maxLeft = el.offsetParent.offsetWidth - el.offsetWidth
        document.onmousemove = e2 => {
          let modalWidth = window.getComputedStyle(modalBox).width;
          modalWidth = parseInt(modalWidth.substring(0, modalWidth.length - 2));
          let modalHeight = window.getComputedStyle(modalBox).height;
          modalHeight = parseInt(modalHeight.substring(0, modalHeight.length - 2));
          let modalLeft = e2.clientX - width;
          let modalTop = e2.clientY - height;
          if (modalLeft < 0) { modalLeft = 0 }
          if (modalTop < 0) { modalTop = 0 }
          if (modalLeft + modalWidth >= watchWidth) { modalLeft = watchWidth - modalWidth; }
          if (modalTop + modalHeight >= watchHeight) { modalTop = watchHeight - modalHeight; }

          modalLeft = modalLeft > maxLeft ? maxLeft : modalLeft
          modalBox.style.position = 'absolute';
          modalBox.style.left = modalLeft + 'px';
          modalBox.style.top = modalTop + 'px';
        };
      };
      modalBox.onmouseup = () => {
        document.onmousemove = null;
        modalBox.style.cursor = '';
      };
    },
    update: (el, binding, vnode) => {
      document.onmouseup = null;
    },
  });

  vue.directive('edit', {
    inserted: function (el, binding, vnode) {
      let div1 = document.createElement('div');
      div1.style.width = '10px'
      div1.style.height = '10px'
      div1.style.position = 'absolute'
      div1.style.bottom = '2px'
      div1.style.right = '2px'
      div1.style.zIndex = 999999
      div1.style.cursor = 'nw-resize'
      div1.style.borderBottom = '1px solid #EEE'
      div1.style.borderRight = '1px solid #EEE'
      el.appendChild(div1)
      div1.onmousedown = e1 => {
        e1.stopPropagation()
        let clientX = e1.clientX;
        let clientY = e1.clientY;
        let width = el.offsetWidth;
        let height = el.offsetHeight;
        document.onmousemove = e2 => {
          let clientX2 = e2.clientX;
          let clientY2 = e2.clientY;
          // 2px 箭头宽 预留
          if (e2.clientX > (el.offsetParent.offsetWidth + el.offsetParent.offsetLeft - 20)) {
            document.onmousemove = null
          }
          el.style.width = width + clientX2 - clientX + 'px';
          el.style.height = height + clientY2 - clientY + 'px';
        };
      };

      div1.onmouseup = e3 => {
        e3.stopPropagation()
        document.onmousemove = null;
        let width = parseInt(el.style.width);
        // 宽高16:9 计算高
        let height = parseInt(width * 9 / 16)
        el.style.height = height + 'px'

      };

      document.onmouseup = e3 => {
        document.onmousemove = null;
      };

    },
    update: (el, binding, vnode) => {

    },
  });

  vue.directive('scroll', {
    inserted: function (el, binding, vnode) {
      let mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll" : "mousewheel" //FF doesn't recognize mousewheel as of FF3.x
      let func = e2 => {
        let widthTmp = parseInt(el.style.width.split('px')[0]);
        let heightTmp = parseInt(el.style.height.split('px')[0]);
        let stemp = 100
        let max = el.offsetParent.offsetWidth - el.offsetLeft - 20
        if ('wheelDelta' in e2) {
          if (e2.wheelDelta > 0) { // 放大
            if (widthTmp < max) {
              let stempWidth = widthTmp + stemp;
              let toWidth = stempWidth > max ? max : stempWidth
              el.style.width = toWidth + 'px';
              el.style.height = parseInt(toWidth * 9 / 16) + 'px';
            } else {
              el.style.width = max + 'px';
              el.style.height = parseInt(max * 9 / 16) + 'px';
            }
          } else { // 缩小
            if (widthTmp > 200) {
              let stempWidth = widthTmp - stemp;
              let toWidth = stempWidth > max ? max : stempWidth
              el.style.width = toWidth + 'px';
              el.style.height = parseInt(toWidth * 9 / 16) + 'px';
            } else {
              el.style.width = 200 + 'px';
              el.style.height = parseInt(200 * 9 / 16) + 'px';
            }
          }
        } else {
          if (e2.detail < 0) { // 放大
            if (widthTmp < max) {
              let stempWidth = widthTmp + stemp;
              let toWidth = stempWidth > max ? max : stempWidth
              el.style.width = toWidth + 'px';
              el.style.height = parseInt(toWidth * 9 / 16) + 'px';
            }
          } else { // 缩小
            if (widthTmp > 200) {
              let stempWidth = widthTmp - stemp;
              let toWidth = stempWidth > max ? max : stempWidth
              el.style.width = toWidth + 'px';
              el.style.height = parseInt(toWidth * 9 / 16) + 'px';
            }
          }
        }
      };

      el.onmouseover = e1 => {
        el.style.cursor = 'zoom-in';
        e1.stopPropagation()
        let width = el.offsetWidth;
        let height = el.offsetHeight;
        el.style.width = width + 'px'
        el.style.height = height + 'px'


        if (document.attachEvent) //if IE (and Opera depending on user setting)
          document.attachEvent("on" + mousewheelevt, func
          )
        else if (document.addEventListener) //WC3 browsers
          document.addEventListener(mousewheelevt, func, false)

      };

      el.onmouseout = e3 => {
        if (document.attachEvent) {
          document.detachEvent(mousewheelevt, func, false)
        } else {
          document.removeEventListener(mousewheelevt, func, false)
        }

      };

    },
    update: (el, binding, vnode) => {

    },
  });

  vue.directive('box', {
    bind: function (el, binding, vnode) {

    }
  })

  vue.directive('permission', permission)




}
