//对照路由表
/**
 * @description 树形数据扁平化
 * @param {*} arrs 
 * @param {*} childs 
 * @param {*} attrArr 
 */
export function flatten(arrs, childs, attrArr) {
  let attrList = [];
  if (!Array.isArray(arrs) && !AudioListener.length) return []
  if (!Array.isArray(attrArr) || Array.isArray(attrArr) && !attrArr.length) {
    attrList = Object.keys(arrs[0]);
    attrList.splice(attrList.indexOf(childs), 1)
  } else {
    attrList = attrArr;
  }
  let list = []
  const deconstruction = (arr) => {
    arr.forEach(row => {
      let obj = {}
      attrList.forEach(item => {
        obj[item] = row[item]
      })
      list.push(obj);
      if (row[childs]) {
        deconstruction(row[childs])
      }
    })
    return list;
  }
  return deconstruction(arrs);
}

/**
 * @description 对照路由表
 * @param {*} router 
 * @param {*} tag 
 */
export function contrast(router, tag) {
  if (router.path === tag.menuUrl) {
    if (router.meta) {
      router.meta.icon = tag.menuIcon
      router.meta.title = tag.menuName
      router.meta.roles = ['user'];
    } else {
      router.meta = {
        roles: ['user']
      }
    }
  }
  router.children && router.children.forEach(router => contrast(router, tag))
}