import { UAV_STATE, UAV_STATE_GREEN, UAV_STATE_ORANGE, UAV_STATE_RED } from '@/enum/UAV_STATE';
import { SITE_HIVE_TYPE } from '@/enum/SITE_HIVE_TYPE';
function stateInterpret(state) {
  return UAV_STATE.getDescFromValue(state);
}

function classNameFilter(state) {
  const classNameList = {
    0: 'white',
    1: 'green',
    102: 'green',
    201: 'blue',
    202: 'blue',
    206: 'blue',
    209: 'blue',
    210: 'blue',
    302: 'blue',
    309: 'blue',
    312: 'blue',
    313: 'green',
    321: 'origin',
    500: 'origin',
    513: 'green',
    522: 'origin',
    601: 'origin',
    602: 'origin',
    603: 'green',
    714: 'origin',

    // 网联加入
    1000: 'green',
    2000: 'green',
    3000: 'green',
    4000: 'green',
    5000: 'green',
    6000: 'green',
    7000: 'origin',
    8000: 'origin',
  };
  return classNameList[state];
}

function uavClassBySubState(state) {
  if (UAV_STATE_GREEN.includes(state)) {
    return 'success';
  } else if (UAV_STATE_ORANGE.includes(state)) {
    return 'warning';
  } else {
    return 'error';
  }
}

function hiveClassBySubState(state) {
  if (UAV_STATE_GREEN.includes(state)) {
    return 'success';
  } else if (UAV_STATE_ORANGE.includes(state)) {
    return 'warning';
  } else {
    return 'error';
  }
}

function classificationChart(state) {
  const chart = {
    0: 'error',
    1: 'success',
    31: 'warning',
    310101: 'warning',
    101: 'warning',
    102: 'success',
    200: 'success',
    201: 'success',
    202: 'success',
    203: 'warning',
    204: 'success',
    206: 'success',
    209: 'warning',
    210: 'warning',
    215: 'warning',
    218: 'success',
    221: 'warning',
    222: 'success',
    224: 'warning',
    300: 'warning',
    301: 'warning',
    302: 'success',
    303: 'success',
    305: 'warning',
    306: 'warning',
    309: 'warning',
    310: 'success',
    312: 'error',
    500: 'error',
    501: 'error',
    502: 'error',
    503: 'warning',
    601: 'warning',
    602: 'error',
    701: 'warning',
    999: 'error',
    1001: 'success',
    1000: 'success',
    2000: 'success',
    3000: 'success',
    4000: 'success',
    5000: 'success',
    6000: 'success',
    7000: 'success',
    8000: 'success',
    1002: 'success',
    1003: 'success',
    1004: 'error',
    1010: 'success',
    空闲: 'success',
    起飞中: 'success',
    待机中: 'success',
    降落中: 'warning',
    回收设备: 'success',
    激活无人机I: 'warning',
    自定义动作: 'warning',
    错误: 'error',
    离线: 'error',
    未知: 'error',

    打开中: 'success',
    关闭中: 'warning',
    无人机充电中: 'warning',
    无人机换电中: 'warning',
    维护中: 'warning',
    故障: 'error',
  };
  return chart[state];
}

function missionStatus(state) {
  state = state.toString();
  const status = {
    0: '未知',
    1: '待命',
    2: '起飞准备中',
    3: '执飞中',
    4: '降落完成中',
    5: '降落完成',
    '-1': '失败',
  };
  return status[state];
}

function missionTagClassName(state) {
  const className = {
    1: 'success',
    '-1': 'danger',
    2: 'danger',
    3: 'info',
    4: 'warning',
    5: 'success',
  };
  return className[state];
}

function interpretSiteModel(mode) {
  return SITE_HIVE_TYPE.SITEMODE.getDescFromValue(mode);
}

function scheduleMissionModeParse(mode) {
  const models = {
    1: '单次',
    2: '每天',
    3: '每周',
    4: '每月',
  };
  return models[mode];
}
function scheduleMissionModeColor(mode) {
  const colors = {
    1: 'pink',
    2: '#69e2b7',
    3: '#274cde',
    4: '#7470e1',
  };
  return colors[mode];
}

function tstatus(status) {
  const statusMap = {
    1: '待审批',
    2: '审批中',
    3: '审批通过',
    4: '审批驳回',
    5: '审批完成',
    6: '撤销',
  };
  return statusMap[status];
}

function messageStatus(status) {
  const statusMap = {
    0: '未读',
    1: '已读',
    2: '全部',
  };
  return statusMap[status];
}

export { uavClassBySubState, hiveClassBySubState, stateInterpret, classNameFilter, classificationChart, missionStatus, missionTagClassName, interpretSiteModel, scheduleMissionModeParse, scheduleMissionModeColor, tstatus, messageStatus };
