import request from '@/utils/request';
import { base_url } from '@/utils/BASE_URL';

const server = base_url.server

//获取项目列表
export function getProjectList(page, size, projectName = '', cpId = '') {
  return request({
    url: `${server}/auth/ProjectManage/ProjectInfoList?pageIndex=${page}&pageSize=${size}&projectName=${projectName}&cpId=${cpId}`,
    method: 'get'
  })
}

//插入项目信息
export function insertProjectInfo(project) {
  return request({
    url: `${server}/auth/ProjectManage/insertProjectInfo`,
    method: 'post',
    data: project
  })
}


//更新项目信息
export function updateProjectInfo(data) {
  return request({
    url: `${server}/auth/ProjectManage/updateProjectInfo`,
    method: 'post',
    data
  })
}

export function deleteProject(pId) {
  return request({
    url: `${server}/auth/ProjectManage/deleteProject?pId=${pId}`,
    method: 'get'
  })
}

/**
 * @description: 企业编号请求项目信息
 * @param {String} cpId
 * @return {*}
 */
export function queryProjectListByCpID(cpId) {
  return request({
    url: `${server}/auth/ProjectManage/queryProjectListByCpID?cpId=${cpId}`,
    method: 'get'
  })
}



