import createEnum from './createEnum';


export const UAV_STATE = createEnum({
  offline: [0, '离线'],
  chuqihua: [31, '初期化'],
  chuqihua2: [310101, '初期化'],
  chushihua: [101, '初始化'],
  daiming: [102, '待命'],
  kaijiku: [200, '开机库'],
  renwuzijian: [201, '任务自检'],
  qifeizijian: [202, '起飞自检'],
  chongdianzhongrenwu: [203, '充电中任务'],
  qifei: [204, '起飞'],
  pasheng: [206, '爬升'],
  renwuzhong: [209, '任务中'],
  ludianrenwu: [210, '陆点任务'],
  dongzuodaiming: [215, '动作待命'],
  dognzuozhixing: [218, '动作执行'],
  zanting: [221, '暂停'],
  xuanting: [222, '悬停'],
  quanshoudongdong: [223, '全手动'],
  ludianxuanting: [224, '陆点悬停'],
  kaijiku2: [300, '开机库'],
  dengzhuangdai: [301, '等状态'],
  zidongfanhang: [302, '自动返航'],
  zidingyifanhang: [303, '自定义返航'],
  beijiangdianjiangluo: [305, '备降点降落'],
  fanhangjiangluo: [306, '返航降落'],
  zidingyijiangluo: [309, '自定义降落'],
  shoudongjierrukongzhi: [310, '手动介入控制'],
  guanjiku: [312, '关机库'],
  yutian: [500, '雨天'],
  wutian: [501, '雾天'],
  dafeng: [502, '大风'],
  yewan: [503, '夜晚'],
  lengque: [601, '冷却'],
  bukefei: [602, '不可飞'],
  kediaofei: [603, '可调飞'],
  shebeiweihu: [701, '设备维护'],
  weizhi: [999, '未知'],
  daiming2: [1000, '待命'],
  wurenjikaiqi: [1001, '无人机开启'],
  tongzhiSDKshangchuan: [1002, '通知OSDK上传'],
  OSDKtupianshangchuanzhong: [1003, 'OSDK图片上传中'],
  wurenjinxuguanbi: [1004, '无人机需关闭'],
  ruanjiangengxin: [1010, '软件更新'],

  // 网联新增

  chuqihuashibai: [1998, '初始化失败'],
  chuqihua3: [1999, '初始化'],
  zhunbeizhong: [2000, '准备中'], // 开机库
  dajiang: [2999, '打桨'],
  qifeizhong: [3000, '起飞中'], // 开机库
  pasheng1: [3999, '爬升'],
  renwuzhong2: [4000, '任务中'],
  fanhangzhong2: [5000, '返航中'], // 雨天
  jiangluozhong2: [6000, '视觉降落中'],
  jinjijiangluo: [6001, '紧急降落中'],
  beijiangjiangluo: [6002, '备降降落中'],
  tingjiang: [6999, '停桨'],
  weihuzhong: [7000, '维护中'],
  chongdianzhong: [8000, '充电中'],
  chongdiankezhifei: [8001, '充电可执飞'],
  chongdianlengque: [8002, '充电冷却'],
  dianliangbuzu: [9998, '电量不足'],
  xuantigndengdai: [9999, '悬停等待'],


  // 小飞机新增状态
  jinjifanhangzhong: [5001, '紧急返航中'],
  zhidianzhongfeixing: [9997, '指点飞行中']

}, '未知');

// 通过自检的标志
export const UAV_STATE_CANFLY = [
  UAV_STATE.daiming,
  UAV_STATE.daiming2,
  UAV_STATE.kediaofei,
  UAV_STATE.chongdiankezhifei
];

export const JOYSTICK_ALLOW_TO_SEND = [
  UAV_STATE.zanting,
  UAV_STATE.dongzuodaiming,
  UAV_STATE.xuantigndengdai,
];



// 无人机在机库中
export const UAV_STATE_IN_HIVE = [
  UAV_STATE.daiming,
  UAV_STATE.daiming2,
  UAV_STATE.kediaofei,
  UAV_STATE.bukefei,
  UAV_STATE.lengque,
  UAV_STATE.yutian,
  UAV_STATE.qifeizijian,
  UAV_STATE.chongdianzhong

];
// 可发送指点飞行指令的标志
export const UAV_ALLOW_TMP_PT = [
  UAV_STATE.daiming,
  UAV_STATE.daiming2,
  UAV_STATE.kediaofei,
  UAV_STATE.chongdiankezhifei,
  UAV_STATE.xuantigndengdai,
  UAV_STATE.dongzuodaiming
];
// 绿色的状态（正常）
export const UAV_STATE_GREEN = [
  UAV_STATE.chuqihua,
  UAV_STATE.chuqihua2,
  UAV_STATE.chushihua,
  UAV_STATE.daiming,
  UAV_STATE.kaijiku,
  UAV_STATE.kaijiku2,
  UAV_STATE.renwuzijian,
  UAV_STATE.qifeizijian,
  UAV_STATE.qifei,
  UAV_STATE.pasheng,
  UAV_STATE.pasheng1,
  UAV_STATE.renwuzhong,
  UAV_STATE.ludianrenwu,
  UAV_STATE.dongzuodaiming,
  UAV_STATE.dognzuozhixing,

  UAV_STATE.xuanting,
  UAV_STATE.quanshoudongdong,
  UAV_STATE.ludianxuanting,
  UAV_STATE.kaijiku,
  UAV_STATE.yewan,
  UAV_STATE.lengque,
  UAV_STATE.kediaofei,
  UAV_STATE.wurenjikaiqi,
  UAV_STATE.ruanjiangengxin,

  // 20220310
  UAV_STATE.daiming2,
  UAV_STATE.xuantigndengdai,
  UAV_STATE.zhunbeizhong,
  UAV_STATE.qifeizhong,
  UAV_STATE.renwuzhong2,
  UAV_STATE.fanhangzhong2,
  UAV_STATE.jiangluozhong2,
  UAV_STATE.weihuzhong,
  UAV_STATE.chongdianzhong,
  UAV_STATE.chongdiankezhifei
];

// 红色的状态（不正常）
export const UAV_STATE_RED = [
  UAV_STATE.offline,
  UAV_STATE.yutian,
  UAV_STATE.wutian,
  UAV_STATE.dafeng,
  UAV_STATE.bukefei,
  UAV_STATE.weizhi,
  UAV_STATE.wurenjinxuguanbi,

  UAV_STATE.chuqihuashibai,
  UAV_STATE.dianliangbuzu,
  UAV_STATE.tingjiang
];

// 橘色的状态（警告）
export const UAV_STATE_ORANGE = [
  UAV_STATE.guanjiku,
  UAV_STATE.chongdianzhongrenwu,
  UAV_STATE.zanting,
  UAV_STATE.dengzhuangdai,
  UAV_STATE.zidongfanhang,
  UAV_STATE.zidingyifanhang,
  UAV_STATE.beijiangdianjiangluo,
  UAV_STATE.fanhangjiangluo,
  UAV_STATE.zidingyijiangluo,
  UAV_STATE.shoudongjierrukongzhi,
  UAV_STATE.shebeiweihu,
  UAV_STATE.tongzhiSDKshangchuan,
  UAV_STATE.OSDKtupianshangchuanzhong,
  UAV_STATE.dajiang,
  UAV_STATE.chongdianlengque

];
