import router from './router';
import store from './store';
import { Message } from 'element-ui';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getToken, setToken, setAuthorization } from '@/utils/auth';
import { getUserNameAndPassword } from '@/service/thirdInterface';
import { login } from '@/api/user';
import getPageTitle from '@/utils/get-page-title';
import { constantRoutes } from '@/router';
import { flatten } from '@/router/comparisonTableRouter.js';
import { routerMap } from '@/router/routerMap.config.js';

NProgress.configure({ showSpinner: false });
let hasRoutes = false; // to store

const whiteList = ['/login', '/register', '/reset-password', '/wechat/login/wechat', '/login:1', '/login:2'];

const filterRoutes = (menus) => {
  return menus.reduce((per, cur) => {
    //查找是否存在此菜单，过滤多余数据
    if (per.find((item) => cur.menuId === item.menuId)) return per;
    //写入数据生成菜单菜单参数
    per.push(cur);
    return per;
  }, []);
};

function redirectToLogin() {
  window.location.href = window.location.origin || `${window.location.protocol}:${window.location.host}`;
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  document.title = getPageTitle(to.meta.title);
  if (getToken()) {
    if (to.path === '/login') {
      next({ path: '/' });
      NProgress.done();
    } else {
      if (hasRoutes) {
        //store.getters.isReady
        next();
      } else {
        try {
          //请求用户权限
          const routes = await store.dispatch('user/getInfo');
          //提取关键字段 临时代码
          const menus = flatten(routes, 'childMens', ['menuId', 'menuLevel', 'menuName', 'parentMenuId', 'menuUrl', 'menuIcon']);
          const flatArr = filterRoutes(menus);
          //匹配路由字段合并路由信息
          const tmpRoutes = flatArr.map((cur) => {
            for (let item in routerMap) {
              if (item === cur.menuUrl) {
                const router = routerMap[item];
                const { menuName: title, menuUrl: path, menuIcon: icon, menuId, parentMenuId, menuLevel } = cur;
                if (router.hiddenParent) {
                  return { ...router, ...{ path, menuId, parentMenuId, menuLevel } };
                }
                if (path === 'menu') return {
                  ...router, ...{
                    meta: { title, icon: icon },
                    path: `${path}/:parentMenuId`,
                    menuId,
                    parentMenuId,
                    menuLevel
                  }
                };
                return { ...router, ...{ meta: { title, icon: icon }, path, menuId, parentMenuId, menuLevel } };
              }
            }
          });

          //过滤数据
          const compact = tmpRoutes.filter(Boolean);

          //数组转化为树形结构数据
          function translateDataToTree(flatArr) {
            const [parents, children] = Object.values(
              flatArr.reduce((res, item) => {
                res[item.menuLevel] ? res[item.menuLevel].push(item) : (res[item.menuLevel] = [item]);
                return res;
              }, {}),
            );

            const translator = (parents, children) => {
              parents.forEach((parent) => {
                children.forEach((current, index) => {
                  if (current.parentMenuId === parent.menuId) {
                    let temp = JSON.parse(JSON.stringify(children));
                    temp.splice(index, 1);
                    translator([current], temp);
                    typeof parent.children !== 'undefined' ? parent.children.push(current) : (parent.children = [current]);
                  }
                });
              });
            };
            translator(parents, children);
            return parents;
          }
          const arrs = translateDataToTree(compact);
          const accessRoutes = constantRoutes.concat(arrs);
          router.options.routes = accessRoutes;
          router.addRoutes(accessRoutes);
          //已生成权限路由
          hasRoutes = true;
          next({ ...to, replace: true });
        } catch (error) {
          await store.dispatch('user/logout');
          Message.error(`鉴权失败:${error.reason}`);
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      if (to.path === '/login' && window.location.href.includes('code=3E16087437F2D8290CD3207BC3703915')) {
        //检查code 是否自动登录 todo
        try {
          const res = await getUserNameAndPassword();
          console.log(res)
          const { code, data: { k, v } } = res
          console.log(k, v)
          login({ name: k, password: v })
            .then((response) => {
              console.log(response)
              const { code, reason } = response;
              if ([0, 200, 320780101, 320770101, 320770115, 320790101, 320760101].includes(code)) {
                const { Authorization, refreshToken } = response;
                setToken(refreshToken);
                setAuthorization(Authorization);
                next({ path: '/main' });
              } else {
                this.
                  redirectToLogin();
              }
            })
        } catch (e) {
          redirectToLogin();;
        }

        next({ path: '/main' });
      } else {
        next();
      }

    } else {
      //路由重定向
      next(`/login`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
