<template>
  <i class="theme-switch-wrap">
    <el-image :class="{ dark: isDark }" :src="icon" width="24px" height="24px" @click="handleThemeTogger" />
  </i>
</template>

<script>
import light from '@/assets/images/main/togger/light.png';
import dark from '@/assets/images/main/togger/dark.png';
export default {
  name: 'ToggerTheme',
  data() {
    return {
      icon: dark,
      theme: {
        background: '',
        color: '',
        plain: '',
      },
    };
  },
  computed: {
    isDark() {
      return this.theme.background === 'dark';
    },
  },
  mounted() {
    this.loadCacheTheme();
  },
  methods: {
    toggerSvgFill() {
      const hamburger = document.querySelector('.hamburger');
      hamburger && hamburger.setAttribute('fill', this.theme.background === 'dark' ? '#FFFFFF' : '#313131');
    },

    toggerMainHeadIMG() {
      const MainTitleIMG = document.querySelector('.head-bg');
      MainTitleIMG && (MainTitleIMG.src = this.theme.background === 'dark' ? require('@/assets/images/main/head.png') : require('@/assets/images/main/head-light.png'));
    },

    loadCacheTheme() {
      const str = window.localStorage.getItem('themeConfig');
      this.theme = str
        ? JSON.parse(str)
        : {
            background: 'dark',
            color: 'dark',
             plain: 'plain',
          };
      this.icon = this.theme.background === 'dark' ? dark : light;
      const body = document.body;
      this.toggerSvgFill();
      this.toggerMainHeadIMG();

      // console.log(hamburger);
      body.setAttribute('data-theme-color', this.theme.color);
       body.setAttribute('data-button-plain', this.theme.plain);
      body.setAttribute('data-theme-background', this.theme.background);
    },
    handleThemeTogger() {
      if (this.theme.background === 'light') {
        this.icon = light;
        window.localStorage.setItem(
          'themeConfig',
          JSON.stringify({
            background: 'dark',
            color: 'dark',
            plain: 'plain',
          }),
        );
      } else {
        this.icon = dark;
        window.localStorage.setItem(
          'themeConfig',
          JSON.stringify({
            background: 'light',
            color: 'light',
            plain: '',
          }),
        );
      }
      this.loadCacheTheme();
    },
  },
};
</script>

<style lang="scss" scoped>
.theme-switch-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  /deep/ .el-image {
    display: flex;
    flex-direction: row;
    align-items: center;
    &.dark {
      filter: invert(1);
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
