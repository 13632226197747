import createEnum from './createEnum';

/* R0001 : 访客；访客不可选择企业，但可以选择某个项目，某个站点 （项目，站点数量为1个）
 * R0002 : 操作员；操作员不可选择企业，可选择多个企业，多个站点 （项目，站点数量可多个）
 * R0003 : 项目管理员；项目管理员不可选择企业，可选择多个项目，站点无法选择（默认全选）
 * R0004 : 企业管理员；企业管理员可选择单个企业（但是绑定了某个企业也是默认选中此单个企业，所以无需选择），项目站点不可选择性操作，默认全选
 * R0005 : 管理员；管理员可选择企业（默认全部选中），项目、站点不可选择（默认全选项目下站点）
 * R0006 : 超级管理员；
 */
export const ROLE = createEnum(
  {
    visitor: ['R0001', '访客'],
    operator: ['R0002', '操作员'],
    projectmanager: ['R0003', '项目管理员'],
    companymanager: ['R0004', '企业管理员'],
    admin: ['R0005', '管理员'],
    superadmin: ['R0006', '超级管理员'],
  },
  '未知',
);
