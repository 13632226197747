<template>
  <div class="alert-message-list-wrap">
    <ul class="alert-message-list">
      <li class="message-item" v-for="message in messages" :key="`${message.msgId}-key`">
        <strong>{{ message.title }}</strong>
        <el-button type="text" @click="handleShowMessageDetails(message.msgId)">确认</el-button>
      </li>
    </ul>
    <el-dialog :close-on-click-modal="false" title="消息详情" :append-to-body="true" :visible="NavBarShowMessage" @close="handleClose">
      <ul>
        <li>
          <strong>信息编号：</strong>
          <span>{{ messageDetails.msgId }}</span>
        </li>
        <li>
          <strong>信息模版编号：</strong>
          <span>{{ messageDetails.msgModelId }}</span>
        </li>
        <li>
          <strong>信息类型：</strong>
          <span>{{ messageDetails.msgType }}</span>
        </li>
        <li>
          <strong>信息主题：</strong>
          <span>{{ messageDetails.title }}</span>
        </li>
        <li>
          <strong>通知时间：</strong>
          <span>{{ $moment(messageDetails.noticeTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </li>
        <li>
          <strong>通知人名称：</strong>
          <span>{{ messageDetails.noticeUserName }}</span>
        </li>
        <li>
          <strong>信息内容：</strong>
          <span>{{ messageDetails.content }}</span>
        </li>
      </ul>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="NavBarShowMessage = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { noticeMsgDetail, noticeMsgRead } from '@/api/message';
import statusInclude from '@/utils/statusCode';
export default {
  name: 'MessageList',
  props: {
    messages: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      NavBarShowMessage: false,
      messageDetails: {},
    };
  },
  methods: {
    async handleShowMessageDetails(msgId) {
      const { code, data, reason } = await noticeMsgDetail(msgId);
      if (!statusInclude(code)) return this.$message.error(`请求失败${reason}`);
      this.messageDetails = data;
      this.NavBarShowMessage = true;
    },
    async handleReadMessage() {
      const msgIds = [];
      msgIds.push(this.messageDetails.msgId);
      const { code, reason } = await noticeMsgRead(msgIds);
      if (!statusInclude) return this.$message.error(`请求失败${reason}`);
      this.$emit('getNewMessageList');
    },
    handleClose() {
      this.handleReadMessage();
      this.messageDetails = {};
      this.NavBarShowMessage = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-message-list-wrap {
  .alert-message-list {
    padding: 0 10px 0 0;
    margin: 0 10px;
    height: 200px;
    overflow-y: auto;
    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.el-dialog__wrapper {
  .el-dialog {
    .el-dialog__body {
      ul {
        padding: 0;
        margin: 0;
        li {
          padding: 10px;
          color: #fff;
        }
      }
    }
  }
}
</style>
