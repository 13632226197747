export const routerMap = {
  '/monitoring': {
    component: () => import('@/layout'),
    hiddenParent: true,
    hidden: false,
  },
  'video-monitoring': {
    component: () => import('@/views/monitoring/index'),
    name: 'VideoMonitoring',
    meta: { title: '视频监控', icon: 'icon-jihuarenwu' },
  },

  '/mission': {
    component: () => import('@/layout'),
    redirect: '/mission/missionStore',
    name: 'Mission',
    hiddenParent: true,
  },
  missionStore: {
    component: () => import('@/views/mission/index'),
    name: 'MissionStore',
    meta: { title: '航线规划', icon: 'icon-hangxianguanli-01' },
  },

  '/schedule': {
    component: () => import('@/layout'),
    hiddenParent: true,
    hidden: false,
  },
  scheduleList: {
    component: () => import('@/views/schedule/index'),
    name: 'ScheduleList',
    meta: { title: '计划任务', icon: 'icon-jihuarenwu' },
  },

  '/center': {
    component: () => import('@/layout'),
    hiddenParent: true,
    hidden: false,
  },
  content: {
    component: () => import('@/views/ControlCenter/index'),
    name: 'Center',
    meta: { title: '飞行控制', icon: 'icon-jihuarenwu' },
  },

  recordersList: {
    component: () => import('@/views/dashboard/index'),
    name: 'RecordersList',
    meta: { title: '历史记录', icon: 'icon-shujutongji' },
  },

  '/flight-results': {
    component: () => import('@/layout'),
    meta: { title: '飞行成果-', icon: 'icon-hangpai' },
    hiddenParent: false,
    hidden: false,
  },

  results: {
    component: () => import('@/views/results/index'),
    name: 'Results',
    meta: { title: '飞行成果', icon: 'icon-shujutongji' },
  },

  '/site': {
    component: () => import('@/layout'),
    name: 'Site',
    redirect: '/site/siteList',
    alwaysShow: true,
    meta: { title: '站点管理', icon: 'icon-zhandianguanli' },
  },
  siteList: {
    component: () => import('@/views/site/SiteList/index'),
    name: 'SiteList',
    meta: { title: '站点列表', icon: 'icon-shouye' },
  },
  'stand-alone-list': {
    component: () => import('@/views/standAlone'),
    name: 'StandAloneList',
    hidden: true,
    meta: { title: '单机列表', icon: 'icon-shouye' },
  },
  addSite: {
    component: () => import('@/views/site/AddSite'),
    name: 'AddSite',
    hidden: true,
    meta: { title: '添加站点', icon: 'icon-shouye' },
  },
  '/layer': {
    component: () => import('@/layout'),
    redirect: '/layer/airspace',
    alwaysShow: true,
    meta: { title: '图层管理', icon: 'icon-zhandianguanli' },
  },
  airspace: {
    component: () => import('@/views/layer/airspace/index'),
    name: 'airspace',
    meta: { title: '空域管理', icon: 'icon-shouye' },
  },

  '/device-store': {
    component: () => import('@/layout'),
    redirect: '/device-store/device-uav-store',
    name: 'DeviceStore',
    meta: { title: '设备库', icon: 'icon-qiyeyonghu' },
  },
  'device-uav-store': {
    component: () => import('@/views/deviceStore/uavStore/index.vue'),
    name: 'DeviceUavStore',
    meta: { title: '无人机设备', icon: 'icon-qiyeyonghu' },
  },
  'device-hive-store': {
    component: () => import('@/views/deviceStore/hiveStore/index.vue'),
    name: 'DeviceHiveStore',
    meta: { title: '机库设备', icon: 'icon-qiyeyonghu' },
  },

  '/device': {
    component: () => import('@/layout'),
    redirect: '/device/hive',
    name: 'Device',
    meta: { title: '设备管理', icon: 'icon-ico_shebeiguanli_shebeipeizhijingzhunyixue' },
  },
  hive: {
    component: () => import('@/views/deviceManagement/hive'),
    name: 'Hive',
    meta: { title: '机库', icon: 'icon-fcbox' },
  },
  uav: {
    component: () => import('@/views/deviceManagement/uav'),
    name: 'UavList',
    meta: { title: '无人机', icon: 'icon-hangpai' },
  },
  battery: {
    component: () => import('@/views/deviceManagement/battery'),
    name: 'BatteryList',
    meta: { title: '电池', icon: 'icon-dianchi' },
  },
  rc: {
    name: 'RemoteControl',
    component: () => import('@/views/deviceManagement/RC'),
    hidden: true,
    meta: { title: '遥控器', icon: 'icon-remote-control' },
  },
  rf: {
    name: 'RadioFrequency',
    component: () => import('@/views/deviceManagement/RFBar'),
    hidden: true,
    meta: { title: '射频杆', icon: 'icon-shepin_fill' },
  },
  oc: {
    name: 'OnboardComputer',
    component: () => import('@/views/deviceManagement/OnboardComputer'),
    hidden: true,
    meta: { title: '天枢', icon: 'icon-cloud-machine' },
  },
  hub: {
    name: 'IndividualCentral',
    component: () => import('@/views/deviceManagement/IndividualCentral'),
    hidden: true,
    meta: { title: '单兵中枢', icon: 'icon-hangpai' },
  },
  '/recorders': {
    component: () => import('@/layout'),
    redirect: '/recorders/recordersList',
    name: 'Recorders',
    hiddenParent: true,
    hidden: false,
  },
  '/monitor': {
    component: () => import('@/layout'),
    redirect: '/monitor/results',
    meta: { title: '数据监控', icon: 'icon-hangpai' },
    name: 'Monitor',
    alwaysShow: true,
  },

  'monitoring-group': {
    component: () => import('@/views/monitoringGroup/index'),
    meta: { title: '视频广场', icon: 'icon-hangpai' },
    name: 'MonitoringGroup',
    // hiddenParent: true,
    // hidden: false
  },

  'traffic-monitoring': {
    component: () => import('@/views/monitoringGroup/index'),
    meta: { title: '流量监控', icon: 'icon-hangpai' },
    name: 'MonitoringGroup',
  },

  '/approval': {
    component: () => import('@/layout'),
    rediren: '/approval/approval-list',
    alwaysShow: false, //是否显示父级路由
    // meta: { title: '审批管理', icon: 'icon-gongdan' },
    hiddenParent: true,
  },

  // '/user': {
  //   component: () => import('@/layout'),
  //   redirect: '/user/userList',
  //   alwaysShow: true,
  //   hidden: false,
  //   meta: { title: '用户管理', icon: 'icon-yonghuguanli' },
  // },
  // 'userList': {
  //   component: () => import('@/views/user/index.vue'),
  //   name: 'UserList',
  //   hidden: false,
  //   meta: { title: '用户列表', icon: 'icon-yonghu' },
  // },
  role: {
    component: () => import('@/views/user/Role/index.vue'),
    name: 'Role',
    hidden: false,
    meta: { title: '角色管理', icon: 'icon-jiaoseguanli' },
  },

  '/events': {
    component: () => import('@/layout'),
    redirect: '/events/operationLog',
    name: 'Events',
    alwaysShow: false,
    hidden: false,
    meta: { title: '日志文件', icon: 'icon-rizhiguanli' },
  },
  message: {
    component: () => import('@/views/log/AdminMessage'),
    name: 'Message',
    meta: { title: '信息审核', icon: 'icon-xitongrizhi' },
  },
  systemLog: {
    component: () => import('@/views/log/SystemLog'),
    name: 'SystemLog',
    hidden: false,
    meta: { title: '系统日志', icon: 'icon-xitongrizhi' },
  },
  operationLog: {
    component: () => import('@/views/log/OperationLog'),
    name: 'OperationLog',
    hidden: false,
    meta: { title: '操作日志', icon: 'icon-caozuorizhi' },
  },
  organization: {
    component: () => import('@/views/enterprise'),
    name: 'AdminOrganization ',
    meta: { title: '企业管理', icon: 'icon-a-yewuguanliqiyeguanli' },
  },
  menu: {
    component: () => import('@/views/administrator/menu/index.vue'),
    name: 'Menu',
    hidden: false,
    meta: { title: '菜单管理', icon: 'icon-caidanguanli' },
  },
  'user-group': {
    component: () => import('@/views/administrator/user/index.vue'),
    name: 'AdminUserGroup',
    hidden: false,
    meta: { title: '用户管理', icon: 'icon-qiyeyonghu' },
  },
  'role-group': {
    component: () => import('@/views/administrator/role/index.vue'),
    name: 'AdminRoleGroup',
    hidden: false,
    meta: { title: '角色管理', icon: 'icon-jiaoseguanli' },
  },
  audit: {
    component: () => import('@/views/administrator/accraditation'),
    name: 'AdminAudit',
    meta: { title: '我的审批', icon: 'icon-qiyeyonghu' },
  },
  '/message': {
    component: () => import('@/layout'),
    name: 'Message',
    meta: { title: '消息管理', icon: 'icon-xiazai41' },
  },
  all: {
    component: () => import('@/views/message/all'),
    name: 'All',
    meta: { title: '全部消息', icon: 'icon-quanbuxiaoxi' },
  },
  unread: {
    component: () => import('@/views/message/unread'),
    name: 'Unread',
    meta: { title: '未读消息', icon: 'icon-weiduxinxi' },
  },
  read: {
    component: () => import('@/views/message/read'),
    name: 'Read',
    meta: { title: '已读消息', icon: 'icon-yiduxiaoxi' },
  },
  '/organization': {
    component: () => import('@/layout'),
    redirect: '/organization/projectgroup',
    meta: { title: '企业组织', icon: 'icon-zuzhi' },
    alwaysShow: true,
  },

  projectgroup: {
    component: () => import('@/views/project'),
    name: 'ProjectGroup',
    meta: { title: '项目管理', icon: 'icon-xiangmuzuguanli' },
  },

  firminuser: {
    component: () => import('@/views/user/index.vue'),
    name: 'Firminuser',
    hidden: false,
    meta: { title: '用户管理', icon: 'icon-qiyeyonghu' },
  },

  '/work': {
    component: () => import('@/layout'),
    rediren: '/work/work-list',
    // hiddenParent: true,
    hidden: false,
  },

  'work-list': {
    name: 'WorkList',
    meta: { title: '我的工单', icon: 'icon-gongdan' },
    component: () => import('@/views/workList'),
  },

  'order-approval': {
    path: 'order-approval',
    name: 'OrderApproval',
    meta: { title: '审批列表', icon: 'icon-gongdan' },
    component: () => import('@/views/approval'),
  },
};
