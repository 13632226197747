<template>
	<div v-if="!levelList || $store.state.app.border.hide"></div>
	<el-breadcrumb class="app-breadcrumb" separator="/" v-else>
		<i class="el-icon-back" @click="$router.go(-1)"></i>
		<el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
			<span :id="`title${index}`" v-if="item.redirect === 'noRedirect' || index == levelList.length - 1" class="no-redirect">{{
					item.meta.title
				}}</span>
			<a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
		</el-breadcrumb-item>
	</el-breadcrumb>
</template>

<script>
import pathToRegexp from 'path-to-regexp';

export default {
	data() {
		return {
			levelList: null,
		};
	},
	watch: {
		$route() {
			this.getBreadcrumb();
		},
	},
	created() {
		this.getBreadcrumb();
	},
	methods: {
		getBreadcrumb() {
			let matched = this.$route.matched.filter((item) => item.meta && item.meta.title);
			const first = matched[0];
			if (!this.isDashboard(first)) {
				// matched = [{ path: '/dashboard', meta: { title: 'Dashboard' } }].concat(matched)
			}
			if (this.isMain(first)) {
				let that = this;
				setTimeout(() => {
					that.levelList = null;
				}, 300);
				return;
			}
			
			this.levelList = matched.filter((item) => item.meta && item.meta.title && item.meta.breadcrumb !== false);
		},
		isDashboard(route) {
			const name = route && route.name;
			if (!name) {
				return false;
			}
			return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
		},
		
		isMain(route) {
			return route.meta.title == '首页';
		},
		pathCompile(path) {
			const {params} = this.$route;
			var toPath = pathToRegexp.compile(path);
			return toPath(params);
		},
		handleLink(item) {
			const {redirect, path} = item;
			if (redirect) {
				this.$router.push(redirect);
				return;
			}
			this.$router.push(this.pathCompile(path));
		},
	},
};
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
	// display: inline-block;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 14px;
	line-height: 30px;
	margin-left: 8px;
	
	i {
		margin: 0 10px 0 0;
		font-size: 20px;
		color: var(--color-primary);
		cursor: pointer;
		
		&:hover {
			color: var(--el-color-primary);
		}
	}
	
	.no-redirect {
		color: var(--color-primary);
		cursor: text;
	}
	
	::v-deep .el-breadcrumb__inner {
		a {
			color: var(--el-color-primary);
			
			&:hover {
				color: #{$primary};
			}
		}
	}
}
</style>
