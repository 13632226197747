import SAlert from './Alert';
import SvgIcon from './SvgIcon';
import SkyLink from './userContainer/components/ForgotPassword.vue';
import SkyAppendButton from './userContainer/components/SkyAppendButton.vue';
import GlobalSearchBar from './GlobalSearchBar';

const conponents = {
  install: function (Vue) {
    Vue.component('SAlert', SAlert);
    Vue.component('SvgIcon', SvgIcon);
    Vue.component('SkyLink', SkyLink);
    Vue.component('SkyAppendButton', SkyAppendButton);
    Vue.component('GlobalSearchBar', GlobalSearchBar);
  },
};

export default conponents;
