module.exports = {
  title: '祺云™无人机集群操作系统',

  // title: '无人机网格化巡检智能机巢管控平台',

  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: true,

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production',
  /**
   * 配置页面高度偏移
   */
  offset: 265,
  /**
   * 配置站点title
   */
  appTitle: "祺云™无人机集群操作系统",

  accessKeyId: 'Q123445545QY',
  accessKeySecret: 'OtVra8YtEfhlxrrs0eY+mUEdz/0sSJvvHdt0LWtDkIc=',


  // accessKeyId: 'QSY23456987QY',
  // accessKeySecret: 'OtVra8YtEfhlxrrs0eY+mUEdz/0sSJvvHdt0LWtDkIc='


  //是否开启识别模块
  isEntityRecognition: true, //false,
};
