export const base_url = {
  server: process.env.VUE_APP_URL + ':' + process.env.VUE_APP_PORT,
  share: process.env.VUE_APP_URL + ':' + process.env.VUE_APP_SHARE_PORT + process.env.VUE_APP_SHARE_SUFFIX,
  ai_url: 'http://124.70.166.47:8880',
  core: process.env.VUE_APP_CORE,
  MAP: process.env.VUE_APP_MAP
}

export const api_url = {
  updateUserProfilePhoto: base_url.server + '/auth/userManager/updateUserProfilePhoto'
}
